<template>
  <material-chart-card
    id="monthlySourceChart"
    :data="sources"
    :options="options"
    :style="cssVars"
    color="pink darken-2"
    type="Bar"
  >
    <h3 class="text-h6 font-weight-light">
      {{ $t("charts.specimen_sources") }}
    </h3>
    <div
      v-if="sources.labels.length && !hideTrend"
      class="category d-inline-flex font-weight-light"
    >
      <span
        v-if="sources.labels.length"
        class="mr-1"
      >
        {{ sources.series[0]?.reduce((a,b) => a + b) | separator }}
      </span>
      Positive Results in the Last {{ days }} Days
    </div>

    <template #actions>
      <v-icon
        class="mr-2"
        small
      >
        mdi-clock-outline
      </v-icon>
      <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ updated | duration('humanize', true) }}</span>
      <v-spacer />
      <card-reload
        :loading="loading.sources"
        @reload="fetchMonthlySources({ no_cache: true, days: days })"
      />
    </template>
  </material-chart-card>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    CardReload: () => import('@/components/CardReload.vue'),
  },
  props: {
    days: {
      type: Number,
      default: 30,
    },
    hideTrend: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapState('charts', ['loading', 'sources']),
    updated() {
      return this.$moment(this.sources.time).diff()
    },
    cssVars() {
      return {
        '--font-size': Math.min(115 / this.days, 10) + "pt",
        '--stroke-width': Math.min(28 / this.sources.series[0]?.length, 8) + "%",
      }
    },
    options() {
      return {
        horizontalBars: true,
        seriesBarDistance: 10,
        chartPadding: {
          top: 14,
          right: 20,
          bottom: 0,
          left: 40,
        },
        axisX: {
          labelOffset: {
            x: -5,
            y: 3,
          },
          height:80,
          labelInterpolationFnc: (value) => value >= 1000 ? Vue.options.filters.number(value, '0.0a') : value,
        },
        axisY: {
          labelInterpolationFnc: (source) => {
            source = this.$t(`sources.${source}`)
            return source === source.toUpperCase() ? source : this.$options.filters.capitalize(source)
          },
        },
      }
    },
  },
  created () {
    if (!this.sources.series[0]?.length || this.$moment().diff(this.sources.time, 'd') > 1) {
      this.fetchMonthlySources({ no_cache: false, days: this.days })
    }
  },
  methods: {
    ...mapActions('charts', ['fetchMonthlySources']),
  },
}
</script>

<style lang="scss">
  #monthlySourceChart .ct-bar {
    stroke-width: var(--stroke-width) !important;
  }
</style>
